/*  highlights-section
    ========================================================================== */
    .highlights-section {
        margin: 4vh 0;
        .grid {
            .item {
                @include media-breakpoint-up(lg) {
                    @include make-col(6);
                }
                @include media-breakpoint-up(xl) {
                    @include make-col(6);
                }
                .card {
                    max-width: 400px;
                    margin: 0 auto;
                    text-decoration: none;
                    background: $primary;
                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 0;
                        height: 0;
                        border-top: 30px solid transparent;
                        border-right: 30px solid #fff;
                    }
                    .card-image {
                        display: none;
                    }
                    .card-body {
                        max-width: 300px;
                        margin: 0 auto;
                        text-align: center;
                        .card-caption {
                            .card-title {
                                font-size: 30px;
                            }
                            .card-title,
                            .card-subtitle,
                            .card-description {
                                color: $white;
                            }
                        }
                        .card-buttons {
                            .card-btn {
                                @extend .btn-secondary;
                            }
                        }
                    }
                    &:hover {
                        background: $secondary;
                        .card-btn {
                            border-color: $primary !important;
                            background: $primary !important;
                        }
                    }
                }
            }
        }
    }


/*  tiles-section
    ========================================================================== */
    .tiles-section {
        margin: 4vh 0;
        .container-fluid {
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }
        .owl-carousel { min-height: 0;}
        .item {
            .card {
                background: $tertiary;
                .card-body {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    background: none;
                    padding: 25px 20px;
                    .card-caption {
                        flex: 0;
                        margin-top: auto;
                        .card-subtitle,
                        .card-title,
                        .card-description {
                            margin-bottom: 0;
                            color: $white;
                            text-shadow: 3px 3px 6px rgba($black, 1);
                        }
                        .card-title { font-style: italic;}
                        .card-description p { margin-bottom: 0;}
                    }
                    .card-buttons {
                        display: none;
                    }
                }
            }
        }
    }


/*  accommodation-types
    ========================================================================== */
    .accommodation-types {
        padding: 4vh 0;
        background-color: $tertiary;
        h3 {
            display: flex;
            align-items: flex-end;
            margin-bottom: 15px;
            img { margin-right: 15px;}
        }
    }


/*  spacer
    ========================================================================== */
	.spacer {
		min-height: $carousel-min_height;
		@extend .loading-overlay;
		.container-default {
			@extend .px-0;
		}
		&.large {
			min-height: $carousel-min_height_lg;
		}
        &::after {
            content: "";
            z-index: 100;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-top: 30px solid transparent;
            border-right: 30px solid #fff;
            @include media-breakpoint-up(xl) {
                border-top: 60px solid transparent;
                border-right: 60px solid #fff;
            }
        }
        .owl-carousel {
            .item {
                padding: 0;
                .owl-container {
                    max-width: none;
                    margin-top: auto;
                }
                .owl-caption {
                    max-width: 400px;
                    padding: 0 0 15px 0;
                    @include media-breakpoint-up(xl) {
                        padding: 0 0 30px 15px;
                    }
                    .owl-title {
                        margin-bottom: 0;
                        font-size: 24px;
                        font-weight: 700;
                        font-style: italic;
                        line-height: 1.2;
                        text-shadow: 3px 3px 6px rgba($black, 1);
                        @include media-breakpoint-up(xl) {
                            font-size: 36px;
                        }
                    }
                }
            }
        }
	}


/*  content
    ========================================================================== */
    .content {
        margin: 4vh 0;
    }
