.mini-sab {
    position: relative;
    z-index: 100;
    .container-fluid {
        padding: 0;
    }
    @include media-breakpoint-down(md) {
        .container-holder {
            width: 100%;
        }
    }
    @include media-breakpoint-up(lg) {
        height: 0;
        .container-holder {
            position: absolute;
            bottom: 100%;
            margin-bottom: 15px;
        }
    }

    /* mini_search_book */
    .mini_search_book {
        @include media-breakpoint-down(md) {
            padding: 15px;
            background-color: $secondary;
        }
    }

    /* arrow-badge */
    .arrow-badge {
        z-index: -1;
        position: absolute;
        left: 30px;
        bottom: 100%;
        margin-bottom: -30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 144px;
        height: 144px;
        border-radius: 100%;
        background-color: $primary;
        color: $white;
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
              &::before {
            content: "\f175";
            font-family: $font-family-awesome;
            position: absolute;
            top: 5px;
            color: $secondary;
            font-size: 20px;
            font-weight: 900;
            text-align: center;
        }
    }

}