&.accommodation_category_detail,
&.accommodation_detail {
  .eyecatcher {
    @extend .d-none;
    @extend .d-sm-block;
    visibility: hidden;

    @include media-breakpoint-up(sm) {
      visibility: visible;
    }
  }

  section.content {
    @extend .p-0;
  }

  .accommodation_detail {
    &.custom_accommodation_detail {
      @extend .m-0;
      @extend .pb-0;

      .go-back-holder {
        @extend .w-100;
      }

      .content {
        @extend .w-100;
        @extend .pb-0;
      }

      .content-holder {
        @include make-row();
      }

      .persons {
        @extend .d-flex;
        margin: 0 0 10px 0;

        &__icon {
          margin: 0 15px 0 0;
        }
      }

      .action-block {
        @extend .position-relative;

        @extend .d-flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 370px;
        margin: 65px 0 0 0;
        padding: 15px;
        background-color: $primary;
        border: 2px solid $white;

        text-decoration: none;
        color: $white;

        .action-block-label {
          @extend .position-absolute;
          top: -47px;
          right: 15px;
          left: 15px;

          padding: 0 30px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          font-size: $font-size-base * 1.875;
          font-weight: $font-weight-bold;
          text-transform: none;
          color: $white;

          @include media-breakpoint-up(sm) {
            left: unset;
          }
        }

        .action-block-text {
          @include make-col(12);
          @extend .pr-0;
          @extend .pr-sm-3;

          @include media-breakpoint-up(sm) {
            @include make-col(6);
          }

          .action-block-title {
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: $white;
          }
        }

        .action-block-prices {
          @include make-col(12);
          @extend .mt-3;
          @extend .pt-3;

          @extend .mt-sm-0;
          @extend .pt-sm-0;

          border-top: 1px solid $white;

          @extend .text-sm-right;

          @include media-breakpoint-up(sm) {
            @include make-col(6);

            border: none;
          }

          .action-block-old-price { text-decoration: line-through; }
          .action-block-price {
            font-size: $font-size-base * 2.25;
            font-weight: $font-weight-bold;
          }
        }
        
        &:hover {
          background-color: $white;
          border-color: $black;

          cursor: pointer;
          color: $black;

          .action-block-label {
            background-color: $primary;
            color: $white;
          }

          .action-block-text {
            .action-block-title { color: $primary; }
          }

          .action-block-prices {
            border-top: 1px solid $black;

            @include media-breakpoint-up(sm) {
              border: none;
            }

            .action-block-price { color: $secondary; }
          }
        }
      }

      .characteristics-holder {
        @extend .w-100;
        padding: 4vh 0;
        background: $tertiary;


        .characteristics {
          @include make-row();

          .characteristics-category {
            @include make-col-ready();
            @include make-col(12);

            margin: 0 0 15px 0;

            @include media-breakpoint-up(sm) {
              @include make-col(6);
            }

            @include media-breakpoint-up(md) {
              @include make-col(6);
            }

            @include media-breakpoint-up(xl) {
              @include make-col(4);
            }

            .characteristics-category-title {
              display: block;
              margin: 0 0 10px 0;

              font-size: $font-size-base * 1.125;
              font-weight: $font-weight-bold;
            }

            .characteristics-list {
              @extend .m-0;
              @extend .p-0;
              list-style: none;

              .characteristics-list-item {
                @extend .position-relative;
                padding: 0 0 0 30px;

                &::before {
                  @extend .position-absolute;
                  left: 0;

                  @extend .d-block;
                  height: $content-custom-list-item-icon-height;

                  font-family: 'Font Awesome 5 Pro';
                  font-size: $font-size-base;
                  font-weight: $font-weight-bold;
                  line-height: inherit;
                  color: $primary;

                  content: '\f00c';
                }
              }
            }
          }
        }
      }

      .tommy {
        padding: 4vh 0;

        .container { 
          @extend .position-relative;
        }

        .review-badge {
          position: relative;
          z-index: 100;

          @extend .d-none;
          visibility: hidden;

          //@extend .d-flex;
          @extend .flex-column;
          @extend .align-items-center;
          @extend .justify-content-center;

          width: 150px;
          height: 150px;
          margin: -100px auto 0 auto;
          padding: 0 40px;

          background: url('~project/public/images/badge.svg');
          background-size: cover;

          @extend .text-center;
          font-size: 22px;
          font-weight: 700;
          line-height: 0.8;
          
          @include media-breakpoint-up(lg) {
            position: absolute;
            top: -100px;
            right: 0;

            width: 215px;
            height: 215px;
            margin: 0;
            padding: 0 35px;

            font-size: $font-size-base * 1.875;
          }

          .review-rating {
            @extend .d-block;

            font-size: $font-size-base * 2;

            @include media-breakpoint-up(lg) {
              font-size: $font-size-base * 3.75;
            }
          }
        }

        .tommy-title {
          margin: 0 0 15px 0;

          @extend .text-center;
          font-size: $h2-font-size;
          font-weight: $font-weight-bold;
        }

        .view {
          margin: 35px 0 0 0;
        }
      }
    }
  }
}
