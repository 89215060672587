
.footer {
    margin-top: 4vh;
    background-color: $secondary;
    
/*  outro
    ========================================================================== */
    .footer-outro {
        padding: 45px 0;
        color: $white;
        text-align: center;
        .container-holder {
            align-items: center;
            justify-content: center;
        }
        h1, h2, h3, h4, h5, h6 { color: $white;}
        a[href^='tel:'] {
            font-weight: 700;
            text-decoration: none;
            &::before {
                content: "\f095";
                font-family: "Font Awesome 5 Pro";
                font-weight: 900;
                font-size: 20px;
                margin-right: 10px;
            }
        }
        a[href^='mailto:'] {
            font-weight: 700;
            text-decoration: none;
            &::before {
                content: "\f0e0";
                font-family: "Font Awesome 5 Pro";
                font-size: 20px;
                font-weight: 900;
                margin-right: 10px;
            }
        }
        a[href^='tel:'],
        a[href^='mailto:'] {
            &:hover {
                color: $white;
            }
        }
    }

/*  copyright
    ========================================================================== */
    .footer-copyright {
        border-top: 1px solid $white;
        padding: 25px 0;
        font-size: 14px;
        color: $white;
        font-style: italic;
        p {
            margin-bottom: 0;
        }   
        ul {
            @extend .d-flex;
            @extend .justify-content-center;
            @extend .justify-content-md-start;
            @extend .list;
            li {
                + li { margin-left: 20px;}
                a {
                    color: $white;
                    text-decoration: none;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        .column {
            @include media-breakpoint-down(md) {
                @include make-col(12);
                text-align: center;
                ul {
                    justify-content: center !important;
                }
            }
        }
        .column.two {
            @include media-breakpoint-up(lg) {
                text-align: right;
            }
        }
    }

}