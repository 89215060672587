/* ul */
ul.custom-list {
    @extend .list-unstyled;
    li {
        position: relative;
        padding: 0 0 0 25px;
        &::before {
            content: '\f00c';
            position: absolute;
            top: 0;
            left: 0;
            font-family: 'Font Awesome 5 Pro';
            color: $primary;
            font-weight: 900;
        }
    }
}

/* ol */
ol.custom-list {
    @extend .list-unstyled;
    li {
        position: relative;
        padding: 0 0 0 25px;
        &::before {
            content: '\f058';
            position: absolute;
            top: 0;
            left: 0;
            font-family: 'Font Awesome 5 Pro';
            color: $secondary;
            font-weight: 400;
        }
    }
}