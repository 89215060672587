&.accommodation_book {
	padding-top: 0 !important;
	section.content {
		margin-top: 0 !important;
		margin-bottom: 0 !important
	}
	.container {
		padding-left: 0 !important;
		padding-right: 0 !important;
		max-width: none !important;
	}
	
	/* wizard-scope */
	.wizard-scope .wizard-app.generic .slotdata.slotdata-head h1,
	.wizard-scope .wizard-app.generic .slotdata.slotdata-head .info {
		display: none;
	}
	.wizard-scope .wizard-app.generic .action-bar .btn.btn-done,
	.wizard-scope .wizard-app.generic .action-bar .btn.btn-next {
		color: #fff !important; 
	}

}
