.eyecatcher {
	.eyecatcher-container { max-width: none;}
	&.large {
		padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        min-height: 360px;
		overflow: hidden;
    }
    iframe {
        position: absolute;
		top: 0;
		left: 0;
		height: 360px;
		@include media-breakpoint-up(sm) {
			width: 100%;
			height: 100%;
		}
    }
	h1 {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
		padding: 15px;
		color: $white;
		font-size: 24px;
		font-style: italic;
		text-align: center;
		text-shadow: 3px 3px 6px rgba($black, 1);
		@include media-breakpoint-up(xl) {
			font-size: 36px;
		}
	}
}


.owl-carousel {
	.item {

		picture {
			width: 100%;
			height: 100%;
			img {
				height: 100%;
				object-fit: cover;
			}
		}
		.owl-caption-holder {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
