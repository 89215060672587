.header {
    .header-main {
        padding: 5px 15px;
        @include media-breakpoint-up(xl) {
            padding: 0 30px;
        }
        background-color: $secondary;
        .navbar {
            min-height: 40px;
            @include media-breakpoint-up(xl) {
                min-height: 60px;
            }
            padding: 0;
            
            /* navbar-toggler */
            .navbar-toggler {
                display: flex;
                align-items: center;
                margin: 0 auto 0 0;
                padding: 5px 15px;
                border: none;
                outline: none;
                border-radius: 0;
                background: $primary;
                color: $white;
                font-size: 16px;
                font-weight: 700;
                line-height: 30px;
                text-transform: uppercase;
                @include media-breakpoint-up(xl) {
                    display: none !important;
                }
                .navbar-toggler-icon { margin-right: 5px;}
            }

            /* park-logo */
            .park-logo {
                position: absolute;
                top: 0;
                left: 110px;
                max-width: 100px;
                @include media-breakpoint-between(sm, xl) {
                    max-width: 145px;
                }
                @include media-breakpoint-up(xl) {
                    top: 15px;
                    left: 0;
                    max-width: 230px;
                }
            }

            /* menu */
            .menu {
                @include media-breakpoint-down(lg) {
                    order: 4;
                }
                .navbar-nav {
                    .nav-item {
                        @include media-breakpoint-down(lg) {
                            border-top: 1px solid rgba($white, 0.15);
                            &:first-child {
                                border-top: none;
                            }
                            .nav-link {
                                color: $white;
                                text-decoration: none;
                                &:hover,
                                &:focus {
                                    color: $primary;
                                }
                            }
                            &.active {
                                .nav-link {
                                    color: $primary;
                                }
                            }
                            .dropdown-menu {
                                margin: 0 0 0 15px;
                                padding: 0;
                                background: $secondary;
                                border: none;
                            }
                        }
                        @include media-breakpoint-up(xl) {
                            margin-left: 5px;
                            font-size: 16px;
                            font-weight: 700;
                            .nav-link {
                                padding: 10px 15px;
                                color: $white;
                                text-decoration: none;
                                &:hover,
                                &:focus {
                                    background: $primary;
                                    color: $white;
                                }
                            }
                            &.active {
                                .nav-link {
                                    background: $primary;
                                    color: $white;
                                }
                            }
                            .dropdown-menu {
                                background: $secondary;
                                border: none;
                                .nav-item {
                                    margin-left: 0;
                                    font-size: 16px;
                                    font-weight: 400;
                                    .nav-link {
                                        padding: 5px 15px;
                                        background: transparent;
                                        &:hover,
                                        &:focus {
                                            background: transparent;
                                            color: $primary;
                                        }
                                    }
                                    &.active {
                                        .nav-link {
                                            color: $primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /* language */
            .language {
                flex-direction: row;
                margin-left: 25px;
                .nav-item {
                    margin-left: 5px;
                    .nav-link {
                        padding: 0;
                        border-radius: 100%;
                        opacity: .7;
                        img {
                            display: block;
                            width: 22px;
                            height: 22px;
                            border-radius: 100%;
                        }
                        &:hover {
                            opacity: 1;
                        }
                    }
                    &.active {
                        .nav-link {
                            opacity: 1;
                        }
                    }
                }
            }

        }
    }

/*  sticky
    ========================================================================== */
    &.sticky {

        .header-main {
            .navbar {
                .park-logo {
                    max-width: 100px;
                }
            }
        }

    }

}

&.navbar-collapse-active {

    .header-main {
        .navbar {
            .park-logo {
                max-width: 36px;
            }
        }
        
    }

}