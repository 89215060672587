/* ==========================================================================
   body
   ========================================================================== */
   $body-padding: 60px 0 0 0;
   $body-sticky-padding: 60px 0 0 0;

 
/* ==========================================================================
   containers
   ========================================================================== */
   $container-padding: 0 15px; 
   $container-padding_sm: 0 15px;


/* ==========================================================================
    owl-carousel
    ========================================================================== */
    $carousel-min_height: 35vh;
    $carousel-min_height_lg: 60vh;


   /* owl-carousel > eyecatcher
   ========================================================================== */
   $eyecatcher-min_height: 35vh;
   $eyecatcher-min_height_lg: 100vh;