.owl-carousel {
    .owl-nav {
        .owl-prev {
            left: 15px;
            &:hover { left: 12px;}
        }
        .owl-next {
            right: 15px;
            &:hover { right: 12px;}
        }
        .owl-prev,
        .owl-next {
            font-size: 24px;
        }
    }
}