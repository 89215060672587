/*  base
    ========================================================================== */   

    /* headings */
    h1 {
        @include media-breakpoint-down(lg) {
            font-size: $h1-font-size-mobile;
        }
    }
    h2 {
        @include media-breakpoint-down(lg) {
            font-size: $h2-font-size-mobile;
        }
    }

    /* img */
    img {
        max-width: 100%;
        height: auto;
    }

    /* transition */
    a,
    .btn,
    .owl-btn,
    .card-btn { transition: 0.5s !important;}

    /* bg-dark */
    .bg-dark {
        padding: 6vh 0;
        &.content {
            margin: 0;
        }
        color: $white;
        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }
    }