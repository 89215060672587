/*  default
    ========================================================================== */
    .card {
        border: none;
        border-radius: 0;
        text-decoration: none;
        .card-image {
            background: none;
            overflow: hidden;
            .card-img {
                border-radius: 0;
                transition: 0.5s ease-in-out;
            }
            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                width: 0; 
                height: 0; 
                border-top: 30px solid transparent;
                border-right: 30px solid #fff;
            }
        }
        .card-img-overlay {
            .card-caption {
                .card-description {
                    min-height: 0;
                    max-height: none;
                    &:after {
                        @extend .d-none;
                    }
                }
            }
        }
        .card-body {
            background: none;
            .card-caption {
                text-align: inherit;
                .card-title {
                    text-align: inherit;
                }
                .card-subtitle {
                    text-align: inherit;
                }
                .card-description {
                    min-height: 0;
                    max-height: none;
                    text-align: inherit;
                    &:after {
                        @extend .d-none;
                    }
                }
            }
        }
        &:hover {
            .card-img {
                transform: scale(1.05);
            }
        }
    }