@mixin defineColorHSLA($color-name, $value) {
	$hue: hue($value);
	$saturation: saturation($value);
	$lightness: lightness($value);
	$alpha: alpha($value);

	#{$color-name}: unquote("hsla(#{$hue}, #{$saturation}, #{$lightness}, #{$alpha})");
	#{$color-name}-hsla: $hue, $saturation,$lightness, $alpha;
	#{$color-name}-h: $hue;
	#{$color-name}-s: $saturation;
	#{$color-name}-l: $lightness;
	#{$color-name}-a: $alpha;
}

:root {
	@include defineColorHSLA(--w3-primary-color, #003b88);    /* Oostappen Blauw: koppen */
	@include defineColorHSLA(--w3-secondary-color, #008fdb);  /* Oostappen Lichtblauw: buttons + links */
	@include defineColorHSLA(--w3-tertiary-color, #009ee0);   /* Oostappen gradient */
	@include defineColorHSLA(--w3-quaternary-color, #1e4774); /* Oostappen gradient */
}

/*  bootstrap > theme-colors
    ========================================================================== */
	$primary:       var(--w3-primary-color);
	$secondary:     var(--w3-secondary-color);
	$tertiary:		var(--w3-tertiary-color);
    $quaternary:	var(--w3-quaternary-color);
    $dark:          $secondary;
    $light:         $tertiary;

/*  bootstrap > body
    ========================================================================== */
    $link-color:        $primary; // default
    $link-decoration:   underline; // default

/*  bootstrap > font
    ========================================================================== */
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,700;1,400;1,700&display=swap');

    $font-family-mulish:    'Mulish', sans-serif;
    $font-family-awesome:   'Font Awesome 5 Pro';
    $font-family-base:      $font-family-mulish;

/*  bootstrap > typography
    ========================================================================== */
    $font-size-base:        1rem; // 16px
    
    $h1-font-size:          $font-size-base * 2.25;  // 36px
    $h2-font-size:          $font-size-base * 1.875; // 30px
    $h3-font-size:          $font-size-base * 1.5;   // 24px
    $h4-font-size:          $font-size-base * 1.25;  // 20px
    $h5-font-size:          $font-size-base * 1.125; // 18px
    $h6-font-size:          $font-size-base; // 16px
    
    $h1-font-size-mobile:   $font-size-base * 1.5;   // 24px
    $h2-font-size-mobile:   $font-size-base * 1.5;   // 24px

    $headings-font-weight:  700;