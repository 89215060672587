.btn,
.card-btn {
    min-width: 160px;
    padding: 0.475rem 1rem;
    border-radius: 0;
    border-color: $primary;
    background-color: $primary;
    box-shadow: none;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    &:hover {
        border-color: darken($primary, 25%);
        background-color: darken($primary, 25%);
    }
    
    /* btn-outline-primary */
    &.btn-outline-primary {
        border-color: $primary;
        background-color: $white;
        color: $primary;
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }

    /* btn-secondary */
    &.btn-secondary {
        border-color: $secondary;
        background-color: $secondary;
        color: $white;
        &:hover {
            background-color: lighten($secondary, 25%);
            border-color: lighten($secondary, 25%);
        }
    }

    /* btn-outline-secondary */
    &.btn-outline-secondary {
        border-color: $secondary;
        background-color: $white;
        color: $secondary;
        &:hover {
            background-color: $secondary;
            color: $white;
        }
    }

    /* btn-white */
    &.btn-white {
        border-color: $white;
        background-color: $white;
        color: $black;
        &:hover {
            background-color: darken($white, 10%);
            border-color: darken($white, 10%);
        }
    }
}

/* btn-transparent */
.btn-transparent {
    background-color: rgba($white, 0.2);
    border-color: transparent;
    &:hover {
        background-color: lighten($tertiary, 10%);
        border-color: lighten($tertiary, 10%);
    }
}


/*  btn-back
    ========================================================================== */
    .btn-back {
        display: inline-block;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        &::before {
            display: inline-block;
        }
    }